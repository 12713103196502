export class CategoryModel {
    constructor({ name, items }) {
        this.name = name;
        this.items = items;
    }

    static fromSnapshotAndItems(snapshot, items) {
        return new CategoryModel({
            name: snapshot.name,
            items: items.filter(el => snapshot.itemsID.includes(el.id))
        })
    }
}